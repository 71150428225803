import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography';
import { HistoryAggregate } from 'corona';
import Stars from './Stars'

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between"
  },
  starbox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "1em"
  },
  starDescription: {
    paddingLeft: "1em",
  },
  bold: {
    fontWeight: "600"
  },
});

class AverageCoolingStars extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      avgCoolingThisMonth: 0.0,
      avgCoolingLastMonth: 0.0,
    };
  }
  componentDidMount() {
    let start = new Date();
    start.setMonth(start.getMonth()-2, 1);
    let stop = new Date();
    this.props.cooling.getHistory(this.props.uuid, HistoryAggregate.Months,start, stop,this.props.authentication).then((h) => {
      if (h != null && h.length>1) {
        this.setState({avgCoolingThisMonth: h[0].value, avgCoolingLastMonth: h[1].value});
      }
    });
  }

  render() {
    const { classes } = this.props;

    const maxCooling = 50.0;
    const minCooling = 20.0;
    let thisMonthPercent = ((this.state.avgCoolingThisMonth-minCooling)/(maxCooling-minCooling));

    let stars = (<div></div>)
    if (thisMonthPercent!=null && thisMonthPercent>=0.0) {
      stars = (<Stars percent={thisMonthPercent}></Stars>)
    }
    return (<div>
      <Typography variant="h6" color="textSecondary" gutterBottom>Average Cooling</Typography>
      <div className={classes.starbox}>
        {stars}
        <Typography className={classes.starDescription}>September <Typography className={classes.bold}>{this.state.avgCoolingThisMonth} K</Typography></Typography>
      </div>
      <Typography>Your average cooling this month has been is ok at <Typography color="primary" inline className={classes.bold}>{this.state.avgCoolingThisMonth } K</Typography>. This is slightly less than last month where you had an average of <Typography inline className={classes.bold}>{this.state.avgCoolingLastMonth} K</Typography>. It is however slightly better than the average in your area.</Typography>
    </div>);
  }
}
AverageCoolingStars.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => { return state; };

export default withStyles(styles)(connect(mapStateToProps)(AverageCoolingStars));