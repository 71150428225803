import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'

import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import { Typography } from '@material-ui/core';

import AssetViewHeat from './AssetViews/AssetViewHeat';
import { getUsagePoint } from 'corona-react';
import SiteViewLocalGenerator from './SiteViews/SiteViewLocalGenerator';

const styles = theme => ({
  loading: {
    marginTop: '30vh'
  },
  titleTop: {
    marginTop: "1em",
    marginBottom: "0.5em"
  },
  noOverflow: {
    whiteSpace: "nowrap"
  },
  dashPaper: {
    overflowX: "auto",
    marginTop: "4em",
    padding: "1em"
  },
  spline: {
    marginBottom: "1em",
    paddingBottom: "1em"
  },
  mainRow: {
    paddingBottom: "2em"
  }
});

class SiteView extends React.Component {
  timeout = null;
  state = {
    shouldSync: true
  }
  componentWillMount() {
    this.props.onBeforeRender();
  }
  componentDidMount() {
    this.usagePointSync();
  }
  componentWillUnmount() {
    console.log("Component will unmount: "+this.timeout)
    clearTimeout(this.timeout);
  }
  usagePointSync() {
  console.log("usagePoint Synchronize firing for "+ this.props.location.pathname);
  console.log(this);
  this.props.syncUsagePoint();
  this.timeout = setTimeout(() => { this.usagePointSync() }, 20000);
}
  render() {
    console.log("render called with: ",this.props.usagePoint);
    const { classes } = this.props;
    let siteTypes = [];
    let targetUnit;
    let renderable = (this.props.usagePoint != null && this.props.usagePoint.uuid.equals(this.props.match.params.uuid)); // because data is gathered from dispatch

    if (renderable) {
      for (let child of this.props.usagePoint.children) {
        if (child.unit.ports.meters().find((e) => { return e.objectType==="10257"; }) != null) {
          siteTypes.push('HEAT');
          targetUnit = child.unit;
          console.log("Set target unit to:",targetUnit);
        } else if (child.unit.ports.findByIndex("/10243/10/1307") != null) {
          siteTypes.push('INVERTER');
        }
      }

    }
    let view = () => {
      let v = <div></div>;
      if (siteTypes.find((e) => { return e==="INVERTER" }) != null) {
        v = (<SiteViewLocalGenerator usagePoint={this.props.usagePoint}></SiteViewLocalGenerator>)
      }
      if (siteTypes.find((e) => { return e==="HEAT" }) != null) {
        console.log("rendering asset view with:", targetUnit);
        v = (<AssetViewHeat unit={targetUnit}></AssetViewHeat>)
      }
      return (<div>
        <Typography className={classes.titleTop} variant="h3">{this.props.usagePoint.assetid}</Typography>
        <Typography variant="subtitle1" color="textSecondary">{this.props.usagePoint.location.address}</Typography>
        <Paper className={classes.dashPaper}>{v}</Paper>
      </div>)
    }
    return (
      <div>
        { renderable ? view() : <div style={{textAlign: "center"}}><CircularProgress className={classes.loading} /></div> }
    </div>
  );
}
}
SiteView.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => { return state; };
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onBeforeRender: () => dispatch((_, getState) => {
      dispatch(getUsagePoint(ownProps.match.params.uuid));
    }),
    syncUsagePoint: () => dispatch((_, getState) => {
      dispatch(getUsagePoint(ownProps.match.params.uuid));
    }),
    getUsagePoint
  };
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SiteView));