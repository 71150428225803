import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography';
import { HistoryAggregate } from 'corona';
import Stars from './Stars'

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between"
  },
  starbox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontWeight: "600",
    marginBottom: "1em"
  },
  starDescription: {
    paddingLeft: "1em",
  },
  bold: {
    fontWeight: "600",
  }
});

class EfficiencyStars extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      avgThisMonth: 0.0,
      avgLastMonth: 0.0,
      prodThisMonth: 0.0,
      prodLastMonth: 0.0,
      gotData: false
    };
  }
  componentDidMount() {
    let start = new Date();
    start.setMonth(start.getMonth()-2, 1);
    let stop = new Date();
    this.props.port.getHistory(this.props.uuid, HistoryAggregate.Months,start, stop,this.props.authentication).then((h) => {
      if (h.length>1) {
        console.log("Got stuff");
        this.setState({avgThisMonth: h[0].value, avgLastMonth: h[1].value, gotData: true});
      }
    });
    this.props.labelPort.getHistory(this.props.uuid, HistoryAggregate.Months,start, stop,this.props.authentication).then((h) => {
      if (h.length>1) {
        console.log("Got stuff 2");
        console.log(h);
        this.setState({prodThisMonth: this.props.labelPort.value-h[0].value, prodLastMonth: h[0].value - h[1].value, gotData: true});
      }
    });
  }

  render() {
    const { classes } = this.props;

    const max  = this.props.peak;
    const peakToAverageFactor = 0.5;
    const GHI = 0.75;

    const min = 0.0;
    if (this.state.gotData) {
      let thisMonthPercent = ((this.state.avgThisMonth-min)/(max*peakToAverageFactor*GHI-min));
      let stars = (<div></div>)
      if (thisMonthPercent!=null && thisMonthPercent>=0.0) {
        stars = (<Stars percent={thisMonthPercent}></Stars>)
      }
      return (<div>
        <Typography variant="h6" color="textSecondary" gutterBottom>Keskimääräinen tuotanto</Typography>
        <div className={classes.starbox}>
          {stars}
          <Typography className={classes.starDescription}>{(this.state.prodLastMonth/this.props.peak).toFixed(1)} kWh/kWp </Typography>
        </div>
        {this.state.prodLastMonth>0.0 &&  <Typography>Tämän kuukauden keskimääräinen tuotanto on 2.14% korkeampi viime vuoteen verrattuna. Alueen auringon säteilytehoarvojen mukaan tuotantonne toimii optimaalisesti.</Typography>}
        {this.state.prodLastMonth===0.0 &&  <Typography>Tarpeeksi dataa ei ole saatavilla vielä (kuukauden historia vaaditaan).</Typography>}
      </div>);
    }
    return (<Typography>Tarpeeksi dataa ei ole saatavilla vielä (kuukauden historia vaaditaan).</Typography>)
  }
}
EfficiencyStars.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => { return state; };

export default withStyles(styles)(connect(mapStateToProps)(EfficiencyStars));