import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { Typography } from '@material-ui/core';

import PortSpline from "../PortSpline";
import EnergyProduction from "../EnergyProduction";
import AverageCooling from "../AverageCooling";
import AverageCoolingStars from "../AverageStars";
import AnnualUsage from "../AnnualUsage";

const styles = theme => ({
  loading: {
    marginTop: '30vh'
  },
  titleTop: {
    marginTop: "1em",
    marginBottom: "0.5em"
  },
  noOverflow: {
    whiteSpace: "nowrap"
  },
  dashPaper: {
    overflowX: "auto",
    marginTop: "4em",
    padding: "1em"
  },
  spline: {
    marginBottom: "1em",
    paddingBottom: "1em"
  },
  mainRow: {
    paddingBottom: "2em"
  }
});

class AssetViewInverter extends React.Component {
  render() {
    const { classes } = this.props;
    let energy = null;
    let cooling = null;
    let power = null;
    if (this.props.unit != null) {
      energy = this.props.unit.ports.findByIndex("/10243/10/18/0");
      cooling = this.props.unit.ports.findByIndex("/10243/10/1307");
      power = this.props.unit.ports.findByIndex("/10243/10/167");
    }

    let now = () => {
      return (<div>
      </div>)
    }
    let billingPeriod = () => {
      return (<div className={classes.mainRow}>
        <Typography variant="h4" gutterBottom>September</Typography>
        <EnergyProduction energy={energy} flow="0.1" uuid={this.props.unit.uuid}></EnergyProduction>
      </div>)
    }
    let year = () => {
      return (<div className={classes.mainRow}>
        <Typography variant="h4" gutterBottom>{(new Date()).getFullYear()}</Typography>
        <AnnualUsage energy={energy} flow="0.1" uuid={this.props.unit.uuid}></AnnualUsage>
      </div>)
    }
    let health = () => {
      return (<div className={classes.mainRow}>
        <Typography variant="h4" gutterBottom>Health overview</Typography>
        <div style={{display: "flex", flexAlign: "row", alignContent: "center"}}>
          <div style={{width: "45%", paddingRight: "5%", paddingBottom: "1em"}}>
          <AverageCoolingStars cooling={cooling} uuid={this.props.unit.uuid}></AverageCoolingStars>
          </div>
          <div style={{width: "45%"}}>
          <PortSpline className={classes.spline} port={cooling} label="Current cooling" uuid={this.props.unit.uuid}></PortSpline>
          </div>
        </div>
        <div style={{display: "flex", flexAlign: "row", alignContent: "center"}}>
          <div style={{width: "45%", paddingRight: "5%"}}>
          <AverageCooling cooling={cooling} uuid={this.props.unit.uuid}></AverageCooling>
          </div>
          <div style={{width: "45%"}}>
          <PortSpline className={classes.spline} port={power} label="Current power" uuid={this.props.unit.uuid}></PortSpline>
          </div>
        </div>
      </div>)
    }
    return (
      <div>
        {billingPeriod()}
        {health()}
        {now()}
        {year()}
      </div>)
  }
}
AssetViewInverter.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => { return state; };

export default withStyles(styles)(connect(mapStateToProps)(AssetViewInverter));