import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography';
import { HistoryAggregate } from 'corona';

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between"
  },
  svgText: {
    fill: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.8rem",
    fontWeight: "400",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  svgTextSecondary: {
    fill: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.8rem",
    fontWeight: "400",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  svgTextBig: {
    fill: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.95rem",
    fontWeight: "500",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  svgTextSecondaryBig: {
    fill: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.95rem",
    fontWeight: "500",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  svg: {
    overflow: "visible"
  }
});

class AverageCooling extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      avgCoolingThisMonth: 0.0,
      avgCoolingLastMonth: 0.0,
    };
  }
  componentDidMount() {
    let start = new Date();
    start.setMonth(start.getMonth()-2, 1);
    let stop = new Date();
    console.log(start);
    console.log(stop);
    this.props.cooling.getHistory(this.props.uuid, HistoryAggregate.Months,start, stop,this.props.authentication).then((h) => {
      console.log(h);
      if (h!=null && h.length>1) {
        this.setState({avgCoolingThisMonth: h[0].value, avgCoolingLastMonth: h[1].value});
      }
    });
  }

  render() {
    const { classes } = this.props;

    const maxCooling = 50.0;
    const minCooling = 20.0;
    let thisMonthPercent = ((this.state.avgCoolingThisMonth-minCooling)/(maxCooling-minCooling))*100+"%";
    let lastMonthPercent = ((this.state.avgCoolingLastMonth-minCooling)/(maxCooling-minCooling))*100+"%";
    return (<div>
     <Typography variant="h6" color="textSecondary" gutterBottom>Average Cooling</Typography>
     <svg style={{width: "100%", height: "140px"}} className={classes.svg}>
       <rect x={0}   y={60} width="35%" height={10} fill="#E53935" rx={5} ry={5}></rect>
       <rect x="60%" y={60} width="40%" height={10} fill="#43A047"rx={5} ry={5}></rect>
       <rect x="33%" y={60} width="33%" height={10} fill="#FDD835"></rect>
       <text x="1%" y={80} className={classes.svgTextSecondary} textAnchor="middle">20 K</text>
       <text x="33%" y={80} className={classes.svgTextSecondary}  textAnchor="middle">30 K</text>
       <text x="66%" y={80} className={classes.svgTextSecondary}  textAnchor="middle">40 K</text>
       <text x="100%" y={80} className={classes.svgTextSecondary} textAnchor="middle">50 K</text>
       <svg x={thisMonthPercent} className={classes.svg}>
        <text textAnchor="middle" x={4} y={9} className={classes.svgText}>2019</text>
        <text textAnchor="middle" x={4} y={20} className={classes.svgText}>September</text>
        <text textAnchor="middle" x={4}  y={35} className={classes.svgTextBig}>{this.state.avgCoolingThisMonth} K</text>
        <path transform="translate(0 40)" d="M0,2.0085302 C0,0.899249601 0.88743329,0 1.99961498,0 L8.00038502,0 C9.10474188,0 10,0.901950359 10,2.0085302 L10,9.9914698 C10,11.1007504 9.42643642,12.6882763 8.72535177,13.5295779 L5,18 L1.27464823,13.5295779 C0.570679453,12.6848153 0,11.0980496 0,9.9914698 L0,2.0085302 Z" fill="rgba(20, 149, 68, 1.0)"></path>
       </svg>
       <svg x={lastMonthPercent} className={classes.svg}>
        <text textAnchor="middle" x={0} y={130} className={classes.svgTextSecondary}>2019</text>
        <text textAnchor="middle" x={0} y={120} className={classes.svgTextSecondary}>August</text>
        <text textAnchor="middle" x={0}  y={105} className={classes.svgTextSecondaryBig}>{this.state.avgCoolingLastMonth} K</text>
        <path transform="translate(0 90) rotate(180)" d="M0,2.0085302 C0,0.899249601 0.88743329,0 1.99961498,0 L8.00038502,0 C9.10474188,0 10,0.901950359 10,2.0085302 L10,9.9914698 C10,11.1007504 9.42643642,12.6882763 8.72535177,13.5295779 L5,18 L1.27464823,13.5295779 C0.570679453,12.6848153 0,11.0980496 0,9.9914698 L0,2.0085302 Z" fill="rgba(26, 199, 91, 1.0)"></path>
       </svg>
     </svg>
    </div>);
  }
}
AverageCooling.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => { return state; };

export default withStyles(styles)(connect(mapStateToProps)(AverageCooling));