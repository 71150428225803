import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { Typography } from '@material-ui/core';

import PortSpline from "../PortSpline";
import EnergyProduction from "../EnergyProduction";
import EfficiencyStars from "../EfficiencyStars";
import AnnualProduction from '../AnnualProduction';

const styles = theme => ({
  loading: {
    marginTop: '30vh'
  },
  titleTop: {
    marginTop: "1em",
    marginBottom: "0.5em"
  },
  noOverflow: {
    whiteSpace: "nowrap"
  },
  dashPaper: {
    overflowX: "auto",
    marginTop: "4em",
    padding: "1em"
  },
  spline: {
    marginBottom: "1em",
    paddingBottom: "1em"
  },
  mainRow: {
    paddingBottom: "2em"
  }
});

class SiteViewLocalGenerator extends React.Component {
  render() {
    const { classes } = this.props;
    let generatedEnergy = null;
    let importedEnergy = null;
    let exportedEnergy = null;
    let generatedPower = null;
    let netPower = null;
    let generatorUUID;
    let mainMeterUUID;
    if (this.props.usagePoint != null) {
      console.log(this.props.usagePoint);
      for (const child of this.props.usagePoint.children) {
        const unit = child.unit;
        if (unit.meters[0].manufacturer==="Fronius") { // NOPE NOPE NOPE
          generatorUUID = unit.uuid;
          generatedEnergy = unit.ports.findByIndex("/10243/10/18/0");
          generatedPower = unit.ports.findByIndex("/10243/10/167");
        } else {
          mainMeterUUID = unit.uuid;
          importedEnergy= unit.ports.findByIndex("/10243/10/18/0");
          exportedEnergy = unit.ports.findByIndex("/10243/10/28/0");
          netPower = unit.ports.findByIndex("/10243/10/167");
        }
      }
      /*energy = this.props.usagePoint.ports.findByIndex("/10243/10/18/0");
      cooling = this.props.usagePoint.ports.findByIndex("/10243/10/1307");
      power = this.props.usagePoint.ports.findByIndex("/10243/10/167");*/

    }

    let billingPeriod = () => {
      if (generatedPower != null && generatedEnergy != null) {
        return (<div className={classes.mainRow}>
          <EnergyProduction generatorPower={generatedPower} produced={generatedEnergy} imported={importedEnergy} exported={exportedEnergy} netPower={netPower} generatorUUID={generatorUUID} mainMeterUUID={mainMeterUUID}></EnergyProduction>
        </div>)
      }
      return (<div className={classes.mainRow}></div>)
    }
    let year = () => {
      return (<div className={classes.mainRow}>
        <Typography variant="h4" gutterBottom>{(new Date()).getFullYear()}</Typography>
        <AnnualProduction generatorPower={generatedPower} produced={generatedEnergy} imported={importedEnergy} exported={exportedEnergy} generatorUUID={generatorUUID} mainMeterUUID={mainMeterUUID}></AnnualProduction>
      </div>)
    }
    const peak = 5.0;
    let health = () => {
      if (generatedPower != null && generatedEnergy != null) {
        return (<div className={classes.mainRow}>
          <Typography variant="h4" gutterBottom>Laitteiston toiminta</Typography>
          <div style={{display: "flex", flexAlign: "row", alignContent: "center"}}>
            <div style={{width: "45%", paddingRight: "5%", paddingBottom: "1em"}}>
            <EfficiencyStars port={generatedPower} labelPort={generatedEnergy} peak={peak} uuid={generatorUUID} ></EfficiencyStars>
            </div>
            <div style={{width: "45%"}}>
            <PortSpline className={classes.spline} port={generatedPower} label="Tuotanto" uuid={generatorUUID}></PortSpline>
            </div>
          </div>
        </div>)
      }
      return (<div className={classes.mainRow}></div>)
    }
    return (
      <div>
        {billingPeriod()}
        {health()}
        {year()}
      </div>)
  }
}
SiteViewLocalGenerator.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => { return state; };

export default withStyles(styles)(connect(mapStateToProps)(SiteViewLocalGenerator));