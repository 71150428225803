import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import MainAppBar from './components/MainAppBar';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import  {BrowserRouter as Router, Route} from 'react-router-dom';
import AssetTable from './components/AssetTable';
import AssetView from './components/AssetView';
import SiteView from './components/SiteView';


const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#F78E1D",
      contrastText: "#ffffff"
    },
    secondary: {
      main: '#2B2B2B',
    },
  },
});

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    padding: theme.spacing.unit * 3,
    height: '100vh',
    flexGrow: 1,
  },
  appPaper: {
    overflowX: "auto"
  },
});


class App extends Component {
  render() {
    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
      <Router>
      <div className={classes.root}>
        <CssBaseline />
        <MainAppBar />
        <main className={classes.content}>
          <Route exact path="/" component={AssetTable} />
          <Route path="/assets/table" component={AssetTable} />
          <Route path="/asset/:uuid" component={AssetView} />
          <Route path="/site/:uuid" component={SiteView} />
        </main>
      </div>
      </Router>
      </MuiThemeProvider>
    );
  }
}
App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);