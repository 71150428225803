import React from 'react';
import ReactDOM from 'react-dom';
//import  {createStore, applyMiddleware} from 'redux';
import { Provider } from 'react-redux';
//import thunk from 'redux-thunk';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from 'corona-react';
import { addRawCredentials, loadLocalCredentials, getUsagePoints, setState, setTime} from "corona-react";
import  { promptLogin } from 'corona';

const store = configureStore();

function synchronize() {
  console.log("Synchronize firing!");
  let t = new Date();
  t.setFullYear("2016");
  store.dispatch(getUsagePoints(t, store.getState().state)).then(() => {
    store.dispatch(setState("FULL"))
    store.dispatch(setTime(new Date()));
  })
  //setTimeout(synchronize, 20000);
}

function renderApplication() {
  if (store.getState().authentication.valid()) {
    //store.dispatch(loadUnits()).then(() => {
      synchronize();
    //});
    ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
  } else {
    console.log("auth was not valid :(");
    console.log(store.getState().authentication);
    console.log(store.getState().authentication.valid());
    promptLogin("4dbf400c-a2ee-49cc-9933-c7acfdb17458"); // prod token for mycomsel.com
  }
}

if (window.location.hash.length>0) {
    store.dispatch(addRawCredentials(window.location.hash)).then(() => {
      window.location.hash = "";
      renderApplication();
    });
} else {
  store.dispatch(loadLocalCredentials()).then(() => {
    renderApplication();
  });
}





// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
