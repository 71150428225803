import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { getUnits, setState, setTime } from 'corona-react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import withRouter from 'react-router-dom/withRouter';

const styles = theme => ({
  noOverflow: {
    whiteSpace: "nowrap"
  }
});

class AssetTable extends React.Component {
  handleRowClick(e) {
    console.log(e);
    //this.router.transitionTo('index');
  }
  componentWillMount() {
    this.props.onBeforeRender();
  }
  //componentWillReceiveProps(nextProps) {}
  render() {
    const { classes } = this.props;
    console.log("table render");
    console.log(this.props);
    console.log(this.props.usagePoints);
    if (this.props.units != null && this.props.units.length===1) {
      this.props.history.push("/asset/"+this.props.units[0].uuid.string());
    }
    if (this.props.usagePoints==null || this.props.usagePoints.length<2) {
      return (<div></div>);
    }
    return (
      <div>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>UUID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Tag</TableCell>
              <TableCell>Site Address</TableCell>
              <TableCell>Last Checked</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.noOverflow}>
            {this.props.usagePoints.map(unit => (
              <TableRow hover key={unit.uuid.string()}  onClick={() => this.props.history.push("/site/"+unit.uuid.string())}>
                <TableCell>
                  {unit.uuid.string()}
                </TableCell>
                <TableCell>
                  {unit.assetid}
                </TableCell>
                <TableCell>
                  {unit.location.name}
                </TableCell>
                <TableCell>
                  {unit.location.address}
                </TableCell>
                <TableCell>
                  {unit.time.toLocaleString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}
AssetTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => { return state; };
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onBeforeRender: () => dispatch((_, getState) => {
      console.log("Hello before render");
      console.log(getState());
      const hasUnits = (getState().units.length>0);
      if (!hasUnits) {
        console.log("dont has units");
        dispatch(getUnits(getState().time, getState().state)).then(() => {
          dispatch(setState("FULL"))
          dispatch(setTime(new Date()));
        })
      }
    }),
    getUnits
  };
}

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetTable)));