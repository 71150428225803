import React from 'react';

const ComselIconColor = <svg width="100%" enableBackground="new -8.851 -4.069 683 171" overflow="visible" version="1.1" viewBox="-8.851 -4.069 683 171">
			<linearGradient id="SVGID_1_" x1="61.966" x2="61.966" y1="151.5" y2="9.3262" gradientUnits="userSpaceOnUse">
				<stop stopColor="#F54029" offset="0"/>
				<stop stopColor="#FF7F1E" offset="1"/>
			</linearGradient>
			<path d="m92.011 113.2 3.412-2.464c4.294-2.526 7.328-3.79 9.099-3.79 3.916 0 8.151 3.539 12.701 10.614 2.779 4.425 4.17 7.961 4.17 10.614 0 2.654-0.853 4.868-2.56 6.635-1.705 1.771-3.696 3.351-5.97 4.739-2.275 1.392-4.456 2.72-6.541 3.981-2.085 1.264-6.35 2.908-12.795 4.928s-12.671 3.034-18.671 3.034c-6.004 0-12.07-0.792-18.198-2.37-6.131-1.579-12.449-4.232-18.956-7.962-6.511-3.726-12.354-8.276-17.535-13.648-5.183-5.369-9.448-12.256-12.795-20.662-3.349-8.401-5.022-17.534-5.022-27.39s1.64-18.796 4.928-26.822c3.285-8.024 7.488-14.658 12.606-19.904 5.118-5.242 10.962-9.634 17.534-13.174 12.384-6.824 24.832-10.236 37.343-10.236 5.938 0 11.972 0.918 18.103 2.748 6.128 1.834 10.772 3.697 13.933 5.592l4.549 2.654c2.274 1.392 4.043 2.529 5.308 3.412 3.284 2.654 4.928 5.657 4.928 9.004 0 3.35-1.327 6.856-3.98 10.521-4.929 6.824-9.354 10.236-13.269 10.236-2.275 0-5.498-1.39-9.668-4.171-5.308-4.294-12.386-6.445-21.23-6.445-8.216 0-16.302 2.843-24.264 8.53-3.792 2.782-6.951 6.7-9.478 11.753-2.529 5.055-3.791 10.775-3.791 17.154 0 6.383 1.262 12.103 3.791 17.157 2.527 5.054 5.749 9.035 9.668 11.94 7.707 5.563 15.733 8.34 24.074 8.34 3.916 0 7.612-0.504 11.089-1.516 3.474-1.007 5.971-2.018 7.487-3.032z" fill="url(#SVGID_1_)"/>
			<linearGradient id="SVGID_2_" x1="183.19" x2="183.19" y1="151.3" y2="47.427" gradientUnits="userSpaceOnUse">
				<stop stopColor="#F54029" offset="0"/>
				<stop stopColor="#FF7F1E" offset="1"/>
			</linearGradient>
			<path d="m146.98 62.212c10.615-9.857 22.747-14.786 36.395-14.786s25.715 4.898 36.206 14.69c10.488 9.795 15.734 22.273 15.734 37.438 0 10.492-2.719 19.809-8.151 27.96-5.436 8.151-12.037 14.154-19.809 18.009-7.772 3.856-15.828 5.781-24.169 5.781s-16.43-2.054-24.264-6.161c-7.837-4.104-14.439-10.171-19.809-18.197-5.373-8.024-8.056-17.09-8.056-27.202 0-15.165 5.308-27.675 15.923-37.532zm23.695 51.086c4.043 3.1 8.151 4.645 12.321 4.645s8.341-1.579 12.511-4.739c4.17-3.156 6.256-7.834 6.256-14.026 0-6.19-1.961-10.807-5.877-13.838-3.918-3.033-8.15-4.55-12.7-4.55s-8.785 1.581-12.7 4.738c-3.919 3.161-5.877 7.804-5.877 13.933-1e-3 6.131 2.02 10.742 6.066 13.837z" fill="url(#SVGID_2_)"/>
			<linearGradient id="SVGID_3_" x1="320.71" x2="320.71" y1="149.6" y2="49.512" gradientUnits="userSpaceOnUse">
				<stop stopColor="#F54029" offset="0"/>
				<stop stopColor="#FF7F1E" offset="1"/>
			</linearGradient>
			<path d="m280.24 62.971c5.938-8.973 13.08-13.459 21.42-13.459 12.89 0 22.492 5.498 28.812 16.492 1.39-1.896 3.063-3.887 5.023-5.972 1.958-2.085 5.087-4.36 9.384-6.824 4.295-2.464 8.721-3.697 13.269-3.697 10.109 0 18.576 3.886 25.4 11.658 6.825 7.772 10.236 20.633 10.236 38.575v33.173c0 2.91-0.094 5.09-0.284 6.54-0.189 1.454-0.789 3.128-1.801 5.023-1.644 3.412-6.509 5.119-14.596 5.119-7.962 0-12.827-1.77-14.597-5.31-0.885-1.896-1.422-3.568-1.61-5.022-0.188-1.451-0.285-3.631-0.285-6.539v-33.173c0-10.994-3.917-16.491-11.751-16.491-4.426 0-7.427 1.454-9.004 4.359-1.582 2.91-2.37 7.015-2.37 12.321v33.173c0 3.033-0.096 5.246-0.284 6.635-0.189 1.392-0.791 3.033-1.801 4.929-1.772 3.412-6.7 5.119-14.786 5.119-7.961 0-12.827-1.77-14.596-5.31-0.886-1.896-1.421-3.568-1.612-5.022-0.189-1.451-0.283-3.631-0.283-6.539v-33.174c0-10.994-3.919-16.491-11.753-16.491-7.582 0-11.373 5.497-11.373 16.491v33.552c0 2.909-0.095 5.09-0.285 6.541-0.189 1.454-0.79 3.127-1.801 5.022-1.896 3.288-6.824 4.93-14.786 4.93-7.961 0-12.828-1.707-14.596-5.119-0.885-1.896-1.421-3.537-1.611-4.929-0.189-1.389-0.285-3.602-0.285-6.635v-66.724c0-2.906 0.096-5.085 0.285-6.54 0.189-1.452 0.788-3.063 1.801-4.834 1.895-3.413 6.38-5.118 13.458-5.118 7.076 0 11.688 1.137 13.838 3.412s3.224 5.563 3.224 9.858z" fill="url(#SVGID_3_)"/>
			<linearGradient id="SVGID_4_" x1="447.71" x2="447.71" y1="151.3" y2="47.807" gradientUnits="userSpaceOnUse">
				<stop stopColor="#F54029" offset="0"/>
				<stop stopColor="#FF7F1E" offset="1"/>
			</linearGradient>
			<path d="m409.71 140.69c-2.402-1.516-3.602-3.915-3.602-7.202 0-3.285 2.399-8.276 7.203-14.977 1.389-2.147 3.631-3.222 6.73-3.222 3.095 0 7.297 1.548 12.604 4.644 5.308 3.1 10.108 4.645 14.407 4.645 7.705 0 11.562-1.517 11.562-4.55 0-2.398-4.235-4.171-12.701-5.308-7.961-1.137-15.859-4.105-23.694-8.908-3.667-2.276-6.7-5.846-9.099-10.711-2.402-4.864-3.602-10.645-3.602-17.345 0-19.965 13.71-29.95 41.135-29.95 8.971 0 18.197 2.15 27.676 6.444 4.421 2.023 6.635 4.55 6.635 7.582 0 3.034-1.393 6.762-4.172 11.185-2.78 4.424-5.624 6.635-8.53 6.635-1.516 0-4.329-0.948-8.435-2.844-4.108-1.896-8.562-2.844-13.364-2.844-7.458 0-11.184 1.392-11.184 4.17 0 3.791 4.295 6.256 12.891 7.393 8.34 0.886 16.425 3.287 24.264 7.204 3.663 1.77 6.728 4.833 9.192 9.193s3.696 9.827 3.696 16.397c0 6.572-1.231 12.163-3.696 16.775-2.465 4.614-5.909 8.056-10.33 10.33-8.089 3.92-18.23 5.877-30.425 5.877-12.195 3e-3 -23.915-3.534-35.161-10.613z" fill="url(#SVGID_4_)"/>
			<linearGradient id="SVGID_5_" x1="548.37" x2="548.37" y1="152.06" y2="47.616" gradientUnits="userSpaceOnUse">
				<stop stopColor="#F54029" offset="0"/>
				<stop stopColor="#FF7F1E" offset="1"/>
			</linearGradient>
			<path d="m597.94 87.708c0 7.771-1.93 13.524-5.782 17.249-3.856 3.729-8.121 5.593-12.795 5.593h-44.926c0 3.54 2.085 6.51 6.257 8.909 4.17 2.402 8.34 3.602 12.51 3.602 7.328 0 13.081-0.759 17.25-2.275l2.085-0.758c3.033-1.39 5.56-2.085 7.582-2.085 4.043 0 7.519 2.843 10.426 8.53 1.642 3.412 2.465 6.32 2.465 8.72 0 11.249-13.458 16.87-40.376 16.87-9.354 0-17.662-1.612-24.927-4.832-7.27-3.224-12.923-7.489-16.966-12.796-7.961-10.237-11.942-21.673-11.942-34.311 0-15.922 5.147-28.653 15.448-38.196 10.3-9.54 23.157-14.312 38.576-14.312 17.563 0 30.454 6.194 38.67 18.578 4.293 6.571 6.445 13.743 6.445 21.514zm-39.998 5.592c4.802 0 7.203-2.019 7.203-6.066 0-2.906-1.108-5.242-3.317-7.013-2.212-1.769-5.373-2.653-9.478-2.653-4.108 0-8.184 1.676-12.226 5.022-4.048 3.35-6.066 6.919-6.066 10.71z" fill="url(#SVGID_5_)"/>
			<linearGradient id="SVGID_6_" x1="636.04" x2="636.04" y1="149.75" y2="2.6914" gradientUnits="userSpaceOnUse">
				<stop stopColor="#F54029" offset="0"/>
				<stop stopColor="#FF7F1E" offset="1"/>
			</linearGradient>
			<path d="m610.26 19.372c0-2.905 0.096-5.085 0.284-6.54 0.189-1.451 0.725-3.128 1.611-5.023 1.769-3.412 6.696-5.118 14.786-5.118 7.707 0 12.51 1.706 14.405 5.118 1.011 1.895 1.612 3.602 1.803 5.118 0.188 1.516 0.283 3.729 0.283 6.635v87.764c0 4.55 0.379 7.428 1.138 8.627 0.758 1.201 2.432 1.799 5.022 1.799s4.327 0.097 5.214 0.285c0.882 0.189 1.957 0.729 3.222 1.61 2.525 1.644 3.791 6.131 3.791 13.459 0 7.963-1.266 12.827-3.791 14.598-3.288 2.272-11.122 2.653-23.505 1.137-9.733-1.264-16.051-4.483-18.956-9.667-3.539-6.19-5.308-16.049-5.308-29.571v-90.231z" fill="url(#SVGID_6_)"/>
</svg>


export default ComselIconColor;