import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography';
import {Bar, Doughnut} from 'react-chartjs-2';
import { HistoryAggregate, convert } from 'corona';

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between"
  },
  lower: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "space-between"
  },
  col: {
    flexDirection: "column"
  },
  left: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    maxWidth: "500px",
    paddingRight: "5%",
  },
  right: {
    width: "50%",
    maxHeight: "400px"
  },
  capitalize: {
    textTransform: "capitalize",
  },
  titleTop: {
    marginTop: "1em",
    marginBottom: "0.5em"
  },
  noOverflow: {
    whiteSpace: "nowrap"
  },
  dashPaper: {
    overflowX: "auto",
    marginTop: "4em"
  },
});

class EnergyCost extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      consumedThisMonth: 0.0,
      dataset: [],
      fixedCost: [],
      labels: [],
    };
  }
  componentDidMount() {
    let start = new Date();
    start.setMonth(start.getMonth()-2, 1);
    let stop = new Date();
    this.props.energy.getHistory(this.props.uuid, HistoryAggregate.Months,start, stop,this.props.authentication, true, "none").then((h) => {
      console.log(this.props.energy.value);
      console.log(h[0]);
      this.setState({consumedThisMonth: (this.props.energy.value-h[0].value)});
    });
    let labels = [];
    let start2 = new Date();
    start2.setDate(0);
    if (stop.getTime()-start2.getTime()<86400000) {
      start2.setMonth(start2.getMonth()-1, 1);
    }
    this.props.energy.getHistory(this.props.uuid, HistoryAggregate.Days,start2, stop,this.props.authentication, true, "delta").then((h) => {
      let dataset = [];
      let fixedCost = [];
      if (h!=null) {
        //for (let i=(h.length-1);i>0;i--) {
        for (let i=0;i<h.length;i++) {
          labels.push(h[i].time.toLocaleDateString());
          dataset.push({
            'x': h[i].time.toISOString(),
            'y': this.wattHoursToEur(convert(h[i].value, this.props.energy.unit, "Wh"), 365, false)})
          fixedCost.push({
            'x': h[i].time.toISOString(),
            'y': (this.baseCostPerFlow(this.props.flow)/365)});
        }
        this.setState({"dataset": dataset, "fixedCost": fixedCost, "labels": labels})
      }
    });
  }
  wattHoursToEur(Wh, yearDivisor, base=true) {
    //const costPerMWh = 56.49; // inc 24% vat
    const costPerWh = 0.00005649; // inc 24% vat
    return Wh*costPerWh+(base ? (this.baseCostPerFlow(this.props.flow)/yearDivisor) : 0.0);
  }
  baseCostPerFlow(flow) {
    const K = 2.247;
    let fee = 2388.20
    let factor =  222.00;
    if (flow<0.51) {
      fee =  874.50;
      factor = 1;
    }
    if (flow<5.0) {
      fee = 134.50;
      factor =  672.70;
    }
    return K*(fee+(factor*flow));
  }
  daysInMonth(d) {
    return 32 - new Date(d.getFullYear(), d.getMonth(), 32).getDate();
  }

  elapsedThisMonth(d) {
    var d2 = new Date(d.getFullYear(), d.getMonth()); // first second of this month
    return d.getTime() - d2.getTime(); // ms this month
  }
  timeInThisMonth(d) {
    var d1 = new Date(d.getFullYear(), d.getMonth()); // first second of this month
    var d2 = new Date(d.getFullYear(), d.getMonth()+1); // first second of next month
    return d2.getTime()-d1.getTime();

  }

  render() {
    const { classes } = this.props;

    const d = {
      labels: this.state.labels,
      datasets: [
        {
          borderColor: 'rgba(14, 210, 109, 0.8)',
          backgroundColor: 'rgba(14, 210, 109, 0.8)',
          data: this.state.fixedCost,
          label: "Fixed",
        },{
          borderColor: 'rgba(0, 159, 238, 0.8)',
          backgroundColor: 'rgba(0, 159, 238, 0.8)',
          data: this.state.dataset.map((v) => { return {x: v.x, y: v.y*0.16 }}),
          label: "Water",
        },{
          borderColor: 'rgba(20, 149, 68, 0.8)',
          backgroundColor: 'rgba(20, 149, 68, 0.8)',
          data: this.state.dataset.map((v) => { return {x: v.x, y: v.y*0.84 }}),
          label: this.props.energy.label,
        },
      ]
    };
    let options = {
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          gridLines: { display: false },
          stacked: true
        }],
        yAxes: [{
          ticks: { min: 0.0 },
          stacked: true
        }]
      },
      tooltips: {
        cornerRadius: 3,
        mode: 'index',
        intersect: false,
        callbacks: {
          label: (tooltipItem, data) => {
            const prefixes = ["Base cost", "Water", "Heating"];
            return  prefixes[tooltipItem.datasetIndex]+": "+tooltipItem.yLabel.toFixed(4) + " €";
          }
        }
      }
    };

    const heatWaterSpit = {
      datasets: [{
        data: [this.state.consumedThisMonth*0.16,this.state.consumedThisMonth*0.84],
        backgroundColor: ['#009fee', 'rgba(20, 149, 68, 0.8)'],
      }],
      labels: ["Water", "Heating"],
    };
    const heatWaterOptions  = {
      cutoutPercentage: 70,
      legend: { position: "bottom"},
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            return " "+data.labels[tooltipItem.index]+" "+convert(data.datasets[0].data[tooltipItem.index], this.props.energy.unit, "kWh").toFixed(2)+" kWh";
          }
        }
      }
    }





    const costToday = this.wattHoursToEur(convert(this.state.consumedThisMonth, this.props.energy.unit, "Wh"), 12, false);
    const costForecast = (costToday/this.elapsedThisMonth(this.props.energy.time))*this.timeInThisMonth(this.props.energy.time)+(this.baseCostPerFlow(this.props.flow)/12);
    return (<div className={classes.root}>
      <div className={classes.left}>
        <Typography variant="h6" color="textSecondary" gutterBottom>Forecasted Cost</Typography>
        <Typography variant="h1">{costForecast.toFixed(2)} €</Typography>
        {<Typography variant="h3">{(costToday+(this.baseCostPerFlow(this.props.flow)/12)).toFixed(2)} € (today)</Typography>}
        <div className={classes.lower}>
          <div>
            <Typography color="textSecondary">Energy</Typography>
            <Typography>{this.state.consumedThisMonth.toFixed(4)} {this.props.energy.unit}</Typography>
          </div>
          <div style={{textAlign: "right"}}>
            <Typography color="textSecondary">Perusmaksu</Typography>
            <Typography>{(this.baseCostPerFlow(this.props.flow)/12).toFixed(2)} €</Typography>
          </div>
        </div>
      </div>
      <div>
        <Typography variant="h6" color="textSecondary" gutterBottom>Heating and water split</Typography>
        <Doughnut data={heatWaterSpit} options={heatWaterOptions}></Doughnut>
      </div>
      <div className={classes.right}>
        <Typography variant="h6" color="textSecondary" gutterBottom>Cost per day</Typography>
        <Bar data={d} legend={{display: false}} height={50} options={options} />
      </div>

    </div>);
  }
}
EnergyCost.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => { return state; };

export default withStyles(styles)(connect(mapStateToProps)(EnergyCost));