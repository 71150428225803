import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography';
import {Bar, Doughnut} from 'react-chartjs-2';
import { HistoryAggregate, convert } from 'corona';

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  lower: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "space-between"
  },
  col: {
    flexDirection: "column"
  },
  left: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    paddingRight: "5%",
  },
  numberBlock: {
    maxWidth: "400px",
  },
  right: {
    width: "50%",
    maxHeight: "400px"
  },
  capitalize: {
    textTransform: "capitalize",
  },
  titleTop: {
    marginTop: "1em",
    marginBottom: "0.5em"
  },
  noOverflow: {
    whiteSpace: "nowrap"
  },
  dashPaper: {
    overflowX: "auto",
    marginTop: "4em"
  },
  noBreak: {
    whiteSpace: "nowrap",
  },
  exporting: {
    color: "#00BCD4",
    width: "48px !important",
    height: "48px !important"
  },
  progress: {
    width: "48px !important",
    height: "48px !important"
  }
});

class EnergyProduction extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      producedThisMonth: 0.0,
      importedThisMonth: 0.0,
      exportedThisMonth: 0.0,
      producedToday: 0.0,
      importedToday: 0.0,
      exportedToday: 0.0,
      producedPerDay: [],
      importedPerDay: [],
      exportedPerDay: [],
      fixedCost: [],
      labels: [],
    };
  }
  componentDidMount() {
    let start = new Date();
    start.setMonth(start.getMonth()-2, 1);
    let stop = new Date();
    this.props.produced.getHistory(this.props.generatorUUID, HistoryAggregate.Months,start, stop,this.props.authentication).then((h) => {
      this.setState({producedThisMonth: (this.props.produced.value-h[0].value)});
    });
    this.props.imported.getHistory(this.props.mainMeterUUID, HistoryAggregate.Months,start, stop,this.props.authentication).then((h) => {
      this.setState({importedThisMonth: (this.props.imported.value-h[0].value)});
    });
    this.props.exported.getHistory(this.props.mainMeterUUID, HistoryAggregate.Months,start, stop,this.props.authentication).then((h) => {
      this.setState({exportedThisMonth: (this.props.exported.value-h[0].value)});
    });

    let labels = [];
    let start2 = new Date();
    start2.setDate(0);
    if (stop.getTime()-start2.getTime()<86400000) {
      start2.setMonth(start2.getMonth()-1);
    }
    this.props.produced.getHistory(this.props.generatorUUID, HistoryAggregate.Days,start2, stop,this.props.authentication, true).then((h) => {
      let producedPerDay = [];
      if (h!=null) {
        for (let i=(h.length-1);i>0;i--) {
          labels.push(h[i].time.toLocaleDateString());
          producedPerDay.push({
            'x': h[i].time.toISOString(),
            'y': h[i-1].value-h[i].value})
        }
        this.setState({"producedToday": this.props.produced.value-h[0].value, "producedPerDay": producedPerDay, "labels": labels})
      }
    });
    this.props.imported.getHistory(this.props.mainMeterUUID, HistoryAggregate.Days,start2, stop,this.props.authentication, true).then((h) => {
      let importedPerDay = [];
      if (h!=null) {
        for (let i=(h.length-1);i>0;i--) {
          importedPerDay.push({
            'x': h[i].time.toISOString(),
            'y': h[i-1].value-h[i].value})
        }
        this.setState({"importedToday": this.props.imported.value-h[0].value, "importedPerDay": importedPerDay})
      }
    });
    this.props.exported.getHistory(this.props.mainMeterUUID, HistoryAggregate.Days,start2, stop,this.props.authentication, true).then((h) => {
      let exportedPerDay = [];
      if (h!=null) {
        for (let i=(h.length-1);i>0;i--) {
          exportedPerDay.push({
            'x': h[i].time.toISOString(),
            'y': -(h[i-1].value-h[i].value)})
        }
        this.setState({"exportedToday": this.props.exported.value-h[0].value, "exportedPerDay": exportedPerDay})
      }
    });
  }
  wattHoursToEur(Wh, yearDivisor, base=true) {
    //const costPerMWh = 56.49; // inc 24% vat
    const costPerWh = 0.00005649; // inc 24% vat
    return Wh*costPerWh+(base ? (this.baseCostPerFlow(this.props.flow)/yearDivisor) : 0.0);
  }
  baseCostPerFlow(flow) {
    const K = 2.247;
    let fee = 2388.20
    let factor =  222.00;
    if (flow<0.51) {
      fee =  874.50;
      factor = 1;
    }
    if (flow<5.0) {
      fee = 134.50;
      factor =  672.70;
    }
    return K*(fee+(factor*flow));
  }
  daysInMonth(d) {
    return 32 - new Date(d.getFullYear(), d.getMonth(), 32).getDate();
  }

  elapsedThisMonth(d) {
    var d2 = new Date(d.getFullYear(), d.getMonth()); // first second of this month
    return d.getTime() - d2.getTime(); // ms this month
  }
  timeInThisMonth(d) {
    var d1 = new Date(d.getFullYear(), d.getMonth()); // first second of this month
    var d2 = new Date(d.getFullYear(), d.getMonth()+1); // first second of next month
    return d2.getTime()-d1.getTime();

  }

  render() {
    const { classes } = this.props;

    const d = {
      labels: this.state.labels,
      datasets: [
        {
          borderColor: 'rgba(76, 175, 80 0.8)',
          backgroundColor: 'rgba(76, 175, 80, 0.8)',
          data: this.state.producedPerDay,
          label: "Produced",
          stack: 1,
        },{
          borderColor: 'rgba(247, 142, 29, 1)',
          backgroundColor: 'rgba(247, 142, 29, 1)',
          data: this.state.importedPerDay,
          label: "Imported",
          stack: 1,
        },{
          borderColor: '#00BCD4',
          backgroundColor: '#00BCD4',
          data: this.state.exportedPerDay,
          label: "Exported",
          stack: 1,
        },
      ]
    };
    let options = {
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          gridLines: { display: false },
          stacked: true
        }],
        yAxes: [{
          stacked: true,
        }]
      },
      tooltips: {
        cornerRadius: 3,
        mode: 'index',
        intersect: false,
        callbacks: {
          label: (tooltipItem, data) => {
            if (isNaN(tooltipItem.yLabel)) { return; }
            const prefixes = ["Tuotanto", "Tuonti", "Vienti"];
            return  prefixes[tooltipItem.datasetIndex]+": "+((tooltipItem.datasetIndex===2) ? (-tooltipItem.yLabel).toFixed(3) : tooltipItem.yLabel.toFixed(3)) + " kWh";
          }
        }
      }
    };

    const thisMonthSelfSustain = {
      datasets: [{
        data: [this.state.producedThisMonth, this.state.importedThisMonth, this.state.exportedThisMonth],
        backgroundColor: ["rgba(76, 175, 80, 1.0)", "rgba(247, 142, 29, 1)", "#00BCD4"]
      }],
      labels: ["Tuotanto", "Tuonti", "Vienti"]
    }
    const todaySelfSustain = {
      datasets: [{
        data: [this.state.producedToday, this.state.importedToday,this.state.exportedToday],
        backgroundColor: ["rgba(76, 175, 80, 1.0)", "rgba(247, 142, 29, 1)", "#00BCD4"]
      }],
      labels: ["Tuotanto", "Tuonti", "Vienti"]
    }
    const doughnutOptions = {
      cutoutPercentage: 70,
      legend: { position: "bottom"},
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            return " "+data.labels[tooltipItem.index]+" "+convert(data.datasets[0].data[tooltipItem.index], this.props.produced.unit, "kWh").toFixed(2)+" kWh";
          }
        }
      }
    }





    const prodForecast = (this.state.producedThisMonth/this.elapsedThisMonth(this.props.produced.time))*this.timeInThisMonth(this.props.produced.time)
    //const peak = 5.13;
    //const percentOfPeak = (convert(this.props.generatorPower.value, this.props.generatorPower.unit, "kW")/peak)*100
    //const exporting = (this.props.netPower != null && this.props.netPower.value<0);
    /*const s = function(netpower) {
      if (exporting) {
        return (<Typography>Viet {-convert(netpower.value, netpower.unit, "kW").toFixed(2)} kW tällä hetkeellä</Typography>)
      }
      return (<Typography>Tuot {convert(netpower.value, netpower.unit, "kW").toFixed(2)} kW tällä hetkeellä</Typography>)
    }*/
    return (<div className={classes.root}>
      <div className={classes.row}>
        <div className={classes.left}>
          <Typography variant="h4" gutterBottom>Nyt</Typography>
          <div class={classes.row}>
            <div className={classes.numberBlock}>
              <Typography variant="h6" color="textSecondary">Tuotanto nyt</Typography>
              <Typography variant="h2"> {convert(this.props.generatorPower.value, this.props.generatorPower.unit, "W").toFixed(0)} W</Typography>
              <div className={classes.lower}>
                <div>
                  <Typography>{this.state.producedToday.toFixed(2)} {this.props.produced.unit}</Typography>
                  <Typography color="textSecondary">Tuotanto</Typography>
                </div>
                <div style={{textAlign: "center"}}>
                  <Typography>{this.state.importedToday.toFixed(2)} {this.props.imported.unit}</Typography>
                  <Typography color="textSecondary">Tuonti</Typography>
                </div>
                <div style={{textAlign: "right"}}>
                  <Typography>{this.state.exportedToday.toFixed(2)} {this.props.exported.unit}</Typography>
                  <Typography color="textSecondary">Vienti</Typography>
                </div>
              </div>
            </div>
            {/*<div>
              <Typography>You are utilizing {percentOfPeak.toFixed(2)} % of your peak</Typography>
              {s(this.props.netPower)}
            </div>*/}
            <div>
              <Typography variant="h6" color="textSecondary" gutterBottom>Energian jakautuminen tänään</Typography>
              <Doughnut data={todaySelfSustain} options={doughnutOptions}></Doughnut>
            </div>
          </div>
        </div>
        <div className={classes.left}>
          <Typography variant="h4" gutterBottom>Huhtikuu</Typography>
          <div className={classes.row}>
            <div className={classes.numberBlock}>
              <Typography variant="h6" color="textSecondary">Ennustettu tuotanto</Typography>
              <Typography variant="h2" className={classes.noBreak}>{prodForecast.toFixed(2)} {this.props.produced.unit}</Typography>
              <div className={classes.lower}>
                <div>
                  <Typography>{this.state.producedThisMonth.toFixed(2)} {this.props.produced.unit}</Typography>
                  <Typography color="textSecondary">Tuotanto</Typography>
                </div>
                <div>
                  <Typography>{this.state.importedThisMonth.toFixed(2)} {this.props.imported.unit}</Typography>
                  <Typography color="textSecondary">Tuonti</Typography>
                </div>
                <div style={{textAlign: "right"}}>
                  <Typography>{this.state.exportedThisMonth.toFixed(2)} {this.props.exported.unit}</Typography>
                  <Typography color="textSecondary">Vienti</Typography>
                </div>
              </div>
            </div>
            <div>
              <Typography variant="h6" color="textSecondary" gutterBottom>Energian jakautuminen tässä kuussa</Typography>
              <Doughnut data={thisMonthSelfSustain} options={doughnutOptions}></Doughnut>
            </div>
          </div>
        </div>

      </div>
      <div>
        <Typography variant="h6" color="textSecondary" gutterBottom>Käytto päivittäin</Typography>
        <Bar data={d} legend={{display: false}} height={100} options={options} />
      </div>
    </div>);
  }
}
EnergyProduction.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => { return state; };

export default withStyles(styles)(connect(mapStateToProps)(EnergyProduction));