import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';


import { removeCredentials } from "corona-react";

import { promptLogin } from "corona";
import ComselIconColor from './icons/ComselIconColor';


const styles = theme => ({
  root: {
    margin: '1em 0 0 1em',
    display: 'flex',
    justifyContent: 'space-between',
  },
  navButton: {
    maxWidth: '400px',
  },
});

class MainAppBar extends React.Component {
  state = {
    anchorEl: null,
    open: true,
  };
  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogOut = () => {
    this.props.removeCredentials();
    this.handleMenuClose();
    promptLogin("00000000-0000-0000-0000-000000000000", window.location.origin);
  }

  handleDrawerOpen = () => {
    this.props.handleDrawerOpen();
  }

  render() {
    const { classes } = this.props;
    const anchorEl  = this.state.anchorEl;
    const isMenuOpen = Boolean(anchorEl);

    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}>
        <MenuItem onClick={this.handleMenuClose}>Profile</MenuItem>
        <MenuItem onClick={this.handleMenuClose}>My account</MenuItem>
        <MenuItem onClick={this.handleLogOut}>Log out</MenuItem>
      </Menu>
    );
    return (
      <div className={classes.root}>
        <Button className={classes.navButton} component={Link} to="/">{ ComselIconColor }</Button>

        <IconButton
          aria-owns={isMenuOpen ? 'material-appbar' : undefined}
          aria-haspopup="true"
          onClick={this.handleProfileMenuOpen}
          color="inherit">
          <AccountCircle color="primary" />
        </IconButton>
      {renderMenu}
      </div>
    );
  }
}

MainAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(null, { removeCredentials })(MainAppBar));