import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography';

import {Line} from 'react-chartjs-2';

import { HistoryAggregate } from 'corona';

const styles = theme => ({
  root: {
    display: "flex",
    maxWidth: "100%",
  },
  left: {
    display: "flex",
    flexDirection: "column",
    minWidth: "20%",
  },
  capitalize: {
    textTransform: "capitalize",
  },
});

class PortSpline extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dataset: [],
      labels: [],
    };
  }
  componentDidMount() {
    let dataset = [];
    let labels = [];
    let start = new Date();
    start.setDate(start.getDate()-1);
    start.setHours(0, 0, 0, 0);
    let stop = new Date();
    this.props.port.getHistory(this.props.uuid, HistoryAggregate.Minutes,start, stop,this.props.authentication, false, "none").then((h) => {
      if (h != null) {
        for (let i=(h.length-1);i>0;i--) {
          dataset.push({
            'x': h[i].time.toISOString(),
            'y': h[i].value})
          labels.push(h[i].time.getHours()+":"+h[i].time.getMinutes());
        }
        this.setState({"dataset": dataset, "labels": labels})
      }
    });
  }
  render() {
    const { classes } = this.props;

    const d = (canvas) => {
      const ctx = canvas.getContext("2d")
      let gradient = ctx.createLinearGradient(0,0,0,100);
      // Add colors
      gradient.addColorStop(0.000, 'rgba(20, 149, 68, 0.8)');
      gradient.addColorStop(1.000, 'rgba(20, 149, 68, 0.00)');
      return  {
        labels: this.state.labels,
        datasets: [{
            label: this.props.port.label,
            borderColor: 'rgba(247, 142, 29, 0.8)',
            backgroundColor: 'rgba(255,255,255,0.0)',
            data: this.state.dataset,
            pointRadius: 0,
          }]
      };
    }
    let options = {
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          gridLines: { display: false },
        }],
        yAxes: [{
          display: false,
          gridLines: { display: false },
        }]
      },
      tooltips: {
        cornerRadius: 3,
        mode: 'index',
        intersect: false,
        callbacks: {
          label: (tooltipItem, data) => {
            var label = data.datasets[tooltipItem.datasetIndex].label || '';
            if (label) {
              label += ': ';
            }
            label += tooltipItem.yLabel.toFixed(2);
            return label;
          }
        }
      }
    };
    return (<div>
      <Typography className={classes.capitalize} variant="h6" color="textSecondary" gutterBottom>{this.props.label}</Typography>
      <div className={classes.root}>
        <div className={classes.left}>
          <Typography style={{paddingTop: "20px"}}>{this.props.port.time.toLocaleTimeString()}</Typography>
          <Typography variant="h4">{this.props.port.value} {this.props.port.unit}</Typography>
        </div>
        <Line data={d} legend={{display: false}} options={options} height={100} width={500} />
      </div>
    </div>);
  }
}
PortSpline.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => { return state; };

export default withStyles(styles)(connect(mapStateToProps)(PortSpline));