import React from 'react';

import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import StarBorderIcon from '@material-ui/icons/StarBorder';



class Stars extends React.Component {
  render() {
    const stars = 5;
    let percentToFullStars = function(percent, round=false) {
      if (percent>1.0) { return stars; }
      if (percent==null) { return 0; }
      if (round) { return Math.round(percent*stars); }
      return Math.floor(percent*stars);
    }
    let restFracStars = function(percent) {
      if (percent>1.0) { return 0; }
      return ((stars*percent)-Math.floor(stars*percent))>0.0
    }
    let emptyStars = function(percent) {
      if (restFracStars(percent)) { return stars-percentToFullStars(percent)-1; }
      return stars-percentToFullStars(percent);
    }

    let halfStar = (<div></div>)
    if (restFracStars(this.props.percent)) {
      halfStar = (<StarHalfIcon fontSize="large" color="primary"></StarHalfIcon>)
    }
    return (<div>
      {[...Array(percentToFullStars(this.props.percent))].map((e, i) => <StarIcon fontSize="large" color="primary" key={"star-"+i}></StarIcon>)}
      {halfStar}
      {[...Array(emptyStars(this.props.percent))].map((e, i) => <StarBorderIcon fontSize="large" color="primary" key={"star-empty-"+i} ></StarBorderIcon>)}
    </div>);
  }
}

export default Stars;