import React from 'react';
import { connect } from 'react-redux'
import {Bar} from 'react-chartjs-2';
import { HistoryAggregate } from 'corona';


class AnnualProduction extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      produced: [],
      imported: [],
      exported: [],
      producedLastYear: [],
      importedLastYear: [],
      exportedLastYear: [],
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    };
  }
  componentDidMount() {
    let start = new Date();
    start.setUTCFullYear((start.getUTCFullYear()-1));
    start.setMonth(0);
    start.setDate(0);
    start.setUTCHours(0,0,0);
    let stop = new Date();
    this.props.produced.getHistory(this.props.generatorUUID, HistoryAggregate.Months,start, stop,this.props.authentication, true).then((h) => {
      let dataset = [];
      let producedLastYear = [];
      if (h!=null) {
        for (let i=(h.length-1);i>0;i--) {
          if (h[i].time.getUTCFullYear() === (new Date()).getUTCFullYear()) {
            dataset.push(h[i-1].value-h[i].value)
          } else {
            producedLastYear.push({
              'x': h[i].time,
              'y': h[i-1].value-h[i].value})
          }
        }
        this.setState({"produced": dataset, "producedLastYear": producedLastYear})
      }
    });

    this.props.imported.getHistory(this.props.mainMeterUUID, HistoryAggregate.Months,start, stop,this.props.authentication, true).then((h) => {
      let dataset = [];
      let producedLastYear = [];
      if (h!=null) {
        for (let i=(h.length-1);i>0;i--) {
          if (h[i].time.getUTCFullYear() === (new Date()).getUTCFullYear()) {
            dataset.push({
              'x': h[i].time,
              'y': h[i-1].value-h[i].value})
          } else {
            producedLastYear.push({
              'x': h[i].time,
              'y': h[i-1].value-h[i].value})
          }
        }
        this.setState({"imported": dataset, "importedLastYear": producedLastYear})
      }
    });

    this.props.exported.getHistory(this.props.mainMeterUUID, HistoryAggregate.Months,start, stop,this.props.authentication, true).then((h) => {
      let dataset = [];
      let producedLastYear = [];
      if (h!=null) {
        for (let i=(h.length-1);i>0;i--) {
          if (h[i].time.getUTCFullYear() === (new Date()).getUTCFullYear()) {
            dataset.push({
              'x': h[i].time,
              'y': -(h[i-1].value-h[i].value)})
          } else {
            producedLastYear.push({
              'x': h[i].time,
              'y': -(h[i-1].value-h[i].value)})
          }
        }
        this.setState({"exported": dataset, "exportedLastYear": producedLastYear})
      }
    });
  }
  wattHoursToEur(Wh, yearDivisor, base=true) {
    //const costPerMWh = 56.49; // inc 24% vat
    const costPerWh = 0.00005649; // inc 24% vat
    return Wh*costPerWh+(base ? (this.baseCostPerFlow(this.props.flow)/yearDivisor) : 0.0);
  }
  baseCostPerFlow(flow) {
    const K = 2.247;
    let fee = 2388.20
    let factor =  222.00;
    if (flow<0.51) {
      fee =  874.50;
      factor = 1;
    }
    if (flow<5.0) {
      fee = 134.50;
      factor =  672.70;
    }
    return K*(fee+(factor*flow));
  }
  daysInMonth(d) {
    return 32 - new Date(d.getFullYear(), d, 32).getDate();
  }

  elapsedThisMonth(d) {
    var d2 = new Date(d.getFullYear(), d.getMonth()); // first second of this month
    return d.getTime() - d2.getTime(); // ms this month
  }
  timeInThisMonth(d) {
    var d1 = new Date(d.getFullYear(), d.getMonth()); // first second of this month
    var d2 = new Date(d.getFullYear(), d.getMonth()+1); // first second of next month
    return d2.getTime()-d1.getTime();

  }

  render() {
    let datasets = [];
    if (this.state.producedLastYear && this.state.producedLastYear.length>0) {
      datasets.push({
        borderColor: 'rgba(24, 220, 119, 0.7)',
        backgroundColor: 'rgba(24, 220, 119, 0.7)',
        data: this.state.producedLastYear,
        label: "Tuotanto viime vuonna",
        stack: 1
      })
    }
    if (this.state.importedLastYear && this.state.importedLastYear.length>0) {
      datasets.push({
        borderColor: 'rgba(247, 142, 29, 0.7)',
        backgroundColor: 'rgba(247, 142, 29, 0.7)',
        data: this.state.importedLastYear,
        label: "Tuonti viime vuonna",
        stack: 1
      })
    }
    if (this.state.exportedLastYear&& this.state.exportedLastYear.length>0) {
      datasets.push({
        borderColor: 'rgba(0, 188, 212, 0.7)',
        backgroundColor: 'rgba(0, 188, 212, 0.7)',
        data: this.state.exportedLastYear,
        label: "Vienti viime vuonna",
        stack: 1
      })
    }
    datasets.push({
     borderColor: 'rgba(20, 149, 68, 1.0)',
     backgroundColor: 'rgba(20, 149, 68, 1.0)',
     data: this.state.produced,
     label: "Tuotanto",
     stack: 2
    });
    datasets.push({
     borderColor: 'rgba(247, 142, 29, 1)',
     backgroundColor: 'rgba(247, 142, 29, 1)',
     data: this.state.imported,
     stack: 2,
     label: "Tuonti"
    });
    datasets.push({
     borderColor: '#00BCD4',
     backgroundColor: '#00BCD4',
     data: this.state.exported,
     stack: 2,
     label: "Vienti"
    });

    const d = {
      labels: this.state.labels,
      datasets: datasets
    };
    let options = {
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          gridLines: { display: false },
        }],
        yAxes: [{
        }]
      },
      tooltips: {
        cornerRadius: 3,
        mode: 'index',
        intersect: false,
        callbacks: {
          label: (tooltipItem, data) => {
            if (!isNaN(tooltipItem.yLabel)) {
              const prefix = data.datasets[tooltipItem.datasetIndex].label+": ";
              return  prefix+tooltipItem.yLabel.toFixed(4) + " kWh";
            }
            return "";
          }
        }
      }
    };

    return (<div>
        <Bar data={d} legend={{display: false}} height={200} options={options} />
    </div>);
  }
}

const mapStateToProps = (state) => { return state; };

export default connect(mapStateToProps)(AnnualProduction);