import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { getUnit } from 'corona-react';

import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import { Typography } from '@material-ui/core';

import AssetViewHeat from './AssetViews/AssetViewHeat';
import AssetViewInverter from './AssetViews/AssetViewInverter';

const styles = theme => ({
  loading: {
    marginTop: '30vh'
  },
  titleTop: {
    marginTop: "1em",
    marginBottom: "0.5em"
  },
  noOverflow: {
    whiteSpace: "nowrap"
  },
  dashPaper: {
    overflowX: "auto",
    marginTop: "4em",
    padding: "1em"
  },
  spline: {
    marginBottom: "1em",
    paddingBottom: "1em"
  },
  mainRow: {
    paddingBottom: "2em"
  }
});

class AssetView extends React.Component {
  state = {
    shouldSync: true
  }
  componentWillMount() {
    this.props.onBeforeRender();
  }
  componentDidMount() {
    this.unitSync();
  }
  componentWillUnmount() {
    this.setState({shouldSync: false});
  }
  unitSync() {
  console.log("Unit Synchronize firing!");
  console.log(this);
  this.props.syncUnit();
  if (this.state.shouldSync) {
    setTimeout(() => { this.unitSync() }, 20000);
  }
}
  render() {
    const { classes } = this.props;
    let viewType = 'NONE';
    if (this.props.unit != null) {
      if (this.props.unit.ports.meters().find((e) => { return e.objectType==="10257"; }) != null) {
        viewType = 'HEAT';
      } else if (this.props.unit.ports.findByIndex("/10243/10/1307") != null) {
        viewType = 'INVERTER';
      }
    }
    let view = () => {
      let v = <div></div>;
      if (viewType==='HEAT') {
        v = (<AssetViewHeat unit={this.props.unit}></AssetViewHeat>)
      }
      if (viewType==='INVERTER') {
        v = (<AssetViewInverter unit={this.props.unit}></AssetViewInverter>)
      }
      return (<div>
        <Typography className={classes.titleTop} variant="h3">{this.props.unit.assetid}</Typography>
        <Typography variant="subtitle1" color="textSecondary">{this.props.unit.location.address}</Typography>
        <Paper className={classes.dashPaper}>{v}</Paper>
      </div>)
    }
    return (
      <div>
        { this.props.unit ? view() : <div style={{textAlign: "center"}}><CircularProgress className={classes.loading} /></div> }
    </div>
  );
}
}
AssetView.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => { return state; };
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onBeforeRender: () => dispatch((_, getState) => {
      dispatch(getUnit(ownProps.match.params.uuid));
    }),
    syncUnit: () => dispatch((_, getState) => {
      dispatch(getUnit(ownProps.match.params.uuid));
    }),
    getUnit
  };
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AssetView));